div#cards-panel {
    width: 460px;
    height: 100%;
    flex: 1; 
    min-height: 0px;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
}


@media only screen and (max-width: 460px) {
    div#cards-panel {
        width: 100%;
        /* padding: 0; */
        /* margin-top: auto; */
    }
}

div#cards-panel a {
    text-decoration: none;
    color: inherit;
}

.card {
    width: 100%;
    height: auto;
    max-height: min(100%, 600px);
    box-sizing: border-box;
    background-color: #242424;
    box-shadow: 0 6px 8px #0004;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    pointer-events: all;
    overflow-y: auto;
}

.card.welcome {
    padding: 8px 16px;
}

.card.loading {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card.error {
    padding: 8px 16px;
}

/* #cards-panel img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
} */

.card.current-connection {
    max-width: 400px;
    display: flex;
    flex-direction: column;
}

.card.current-connection .header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid #444;
    padding: 8px;
}

.tracks-list {
    overflow-y: auto;
}

.tracks-list div.track {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background-color: #1c1c1c;
}

.tracks-list div.track a {
    font-size: 12px;
    padding: 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 8px;
    min-width: 0;
}

.tracks-list div.track a.main {
    flex-grow: 1;
    align-items: flex-start;
}

.tracks-list div.track a.play {
    flex-shrink: 0;
    align-items: center;
}

.tracks-list div.track a div {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tracks-list div.track a div.song {
    flex: 0 0 auto;
    height: 18px;
    width: 20px;
    background-image: url(../img/music_note_white_24dp.svg);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    opacity: 0.8;
}

.tracks-list div.track a div.play {
    flex: 0 0 auto;
    height: 18px;
    width: 24px;
    background-image: url(../img/play_arrow_white_24dp.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    opacity: 0.8;
}

.tracks-list div.track a div.pause {
    flex: 0 0 auto;
    height: 18px;
    width: 24px;
    background-image: url(../img/pause_white_24dp.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    opacity: 0.8;
}

.tracks-list div.track a.disabled {
    opacity: 0.2;
}

.tracks-list div.track a div.name {
    flex-grow: 1;
}

.tracks-list div.track a div.year {
    flex-shrink: 0;
}


.tracks-list div.track.more div {
    font-style: italic;
    font-size: 12px;
    padding: 14px;
    box-sizing: border-box;
    color: #aaa;
}

.tracks-list div.track a:not(.disabled):hover {
    background-color: #2c2c2c;
    cursor: pointer;
}

.tracks-list .track:not(:first-child) {
    border-top: 1px solid #ffffff0f;
}

.tracks-list .track.more:hover {
    background-color: #1c1c1c;
}
