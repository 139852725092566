@import "cards.css";
@import "artist.css";

:root {
    --brand-color:#63b7f7;
    --brand-color2:#ffbee4;
}

* {
    transition: background-color 300ms, opacity 300ms, border-color 300ms;
    user-select: none;
}

body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans', sans-serif;
    color: #ddd;
}

html {
    background-color: #242424;
}

body {
    display: flex;
    flex-direction: column;
}

header {
    margin: 0;
    /* max-width: 1000px; */
    width: 100%;
    background-color: #303030;
}

main {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
    line-height: 1.5rem;
    font-size: 15px;
}

h1 {
    font-size: 1.8em;
    /* margin: 0; */
}

h2 {
    margin-block-start: 1.33em;
    margin-block-end: 0.1em;
}

h4 {
    margin-block-start: 1.33em;
    margin-block-end: 0.33em;
}

p {
    font-size: 0.9em;
}

li {
    font-size: 0.9em;
}

@media only screen and (max-width: 560px) {
    .only-desktop {
        display: none !important;
    }   
}

@media only screen and (min-width: 560px) {
    .only-mobile {
        display: none !important;
    }   
}

#graph-area, #overlays-area {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    flex: 1 0 0;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
}

#overlays-area {
    pointer-events: none;
}

#overlays-area > * {
    flex-shrink: 0;
    flex-grow: 0;
}

.top-bar {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin: 20px;
}

.top-bar > * {
    pointer-events: all;
}

.top-bar .app-logo {
    width: 150px;
}

.top-bar .app-logo img {
    width: 100%;
}

.top-bar .menu-section {
    height: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
}

.icon {
    position: relative;
    width: 24px;
    height: 24px;
    opacity: 0.4;
    background-size: 24px 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon.info {
    background-image: url(../img/info_white_24dp.svg);
}

.icon.help {
    background-image: url(../img/help_outline_white_24dp.svg);
}

.icon.config {
    background-image: url(../img/settings_white_24dp.svg);
}

.icon.search {
    background-image: url(../img/search_white_24dp.svg);
}

.icon.close {
    background-image: url(../img/close_white_24dp.svg);
}

.top-bar .menu-icon {
    font-size: 0;
    position: relative;
}

.top-bar .menu-icon:hover .icon, .icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.top-bar .menu-icon .popover-menu {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.top-bar .menu-icon:hover .popover-menu {
    opacity: 1;
    pointer-events: all;
}

.popover-menu#config {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 120px;
    top: 100%;
    right: 0;
    font-size: 12px;
    font-weight: 700;
    background-color: #333;
    padding: 16px 8px;
    border-radius: 4px;
}

input[type="checkbox"],
input[type="checkbox"]+label {
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
}

.app-container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* background-color: #222; */
    background: radial-gradient(closest-side, #2f2f2f, #141414);
}

img {
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
}

canvas {
    width: 100%;
    height: 100%;
}


/* #artist-panel-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 0 400px;
} */


button.action {
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 12px;
    padding: 10px 12px;
    text-align: center;
    text-transform: uppercase;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #fff5;
    border-radius: 4px;
    cursor: pointer;
}

button.action:hover {
    background-color: #fff1;
    border-color: #fffa;
}

button.action:disabled {
    color: #888;
    pointer-events: none;
    border-color: #333;
}

.search-wrapper {
    width: 360px;
    height: 100%;
    position: relative;
    z-index: 2;
    pointer-events: all;
}

.search-wrapper.mobile {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0px 20px;
    margin: -20px 0px 20px 0px;
}

.search-wrapper.mobile .search-box-wrapper::after {
    right: 20px;
}

.search-box-wrapper {
    width: 100%;
    height: 100%;
    background-color: #4448;
    border-radius: 4px;
    overflow: hidden;
}

.search-box-wrapper::after {
    content: '';
    width: 40px;
    height: 100%;
    opacity: 0.4;
    background-image: url(../img/search_white_24dp.svg);
    background-size: 24px 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
}

.search-wrapper input {
    width: 100%;
    height: 100%;
    position: relative;
    outline: 0;
    border: 0;
    box-sizing: border-box;
    padding: 10px;
    font-size: 14px;
    font-weight: 700;
    background-color: transparent;
    color: #ccc;
}

.search-wrapper input:focus {
    background-color: #444a;
}

.search-wrapper input::placeholder {
    color: #fff;
    opacity: 0.4;
}

.search-wrapper input:focus::placeholder {
    opacity: 0.6;
}

.search-wrapper .search-results {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #333e;
    box-sizing: border-box;
    padding: 0px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    height: 0;
    max-height: min(calc(100vh - 80px), 500px);
    overflow-y: auto;
    transition: height 100ms ease-out;
}


.search-wrapper .search-results ul {
    padding: unset;
	margin: 0;
}


.search-wrapper .search-results.show {
    min-height: 200px;
}


.search-wrapper .search-results li {
    list-style: none;
    padding: 6px;
    cursor: pointer;
    border-top:  1px solid #0004;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.search-wrapper .search-results .no-results {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    padding: 18px 24px;
    box-sizing: border-box;
    color: #aaa;
}


.search-wrapper .search-results li.focus {
    background-color: #444;
}


.info-box {
    font-size: 12px;
    background-color: #0004;
    padding: 8px;
    border-radius: 4px;
    margin: 16px;
}


#project-box {
    /* height: 80px; */
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
    pointer-events: all;
}

#config-box {
    min-width: 150px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    pointer-events: all;
}

#debug-box {
    min-width: 150px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    pointer-events: all;
}

#debug-box .row {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
}

#debug-box .row .value {
    font-weight: 700;
}

div.label {
    color: #444;
    font-size: 18px;
    font-weight: 700;
}

.collapsible-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 200ms ease-out;
}

*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    /* -webkit-border-radius: 0px;
    border-radius: 0px; */
}

*::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #fff3;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

*::-webkit-scrollbar-thumb:hover {
    /* border-radius: 10px; */
    background: #fff4;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}


*::-webkit-scrollbar-thumb:window-inactive {
    background: #fff1;
}
