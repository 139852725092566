.card.current-artist {
    max-width: 400px;
    gap: 12px;
}

.card.current-artist .header-image-wrapper {
    height: 240px;
    position: relative;
}

@media only screen and (max-width: 460px) {
    .card.current-artist {
        max-width: unset;
    }

    .card.current-artist .header-image-wrapper {
        height: 210px;
    }
}

.card.current-artist .header-image {
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    box-shadow: 0 8px 24px #0007;
    border-radius: 4px;
}

.artist-basic-info {
    display: flex;
    flex-direction: column;
    padding: 6px 12px 12px 12px;
    gap: 8px;
    background-color: #242424;
    cursor: pointer;
}

.artist-basic-info:hover {
    background-color: #2c2c2c;
}

.artist-basic-info .block {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.artist-basic-info .name {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.artist-basic-info .followers {
    color: #aaa;
    font-size: 12px;
    font-weight: 400;
    padding-left: 2px;
}

.artist-basic-info .logo {
    flex: none;
    margin: 8px;
    height: 28px;
    aspect-ratio: 10/3;
    background-image: url('../img/spotify_logo.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.artist-connections-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-gutter: stable;
    padding: 8px 8px 12px 8px;
    gap: 8px;
    box-sizing: border-box;
}

.artist-connections-list > div {
    flex: 0 0 auto;
    min-width: 0;
}

.artist-image-placeholder {
    width: 150px;
    height: 150px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    opacity: 0.4;
    /* aspect-ratio: 1.5; */
}

.artist-block {
    width: 116px;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    box-sizing: border-box;
    background-color: #2c2c2c;
}

.artist-block.big {
    width: 152px;
    padding: 8px;
}


@media only screen and (max-width: 460px) {
    .artist-block {
        width: 100px;
        padding: 6px;
    }
    
    .artist-block.big {
        width: 128px;
        padding: 6px;
    }
}

.artist-block:hover {
    background-color: #3c3c3c;
    box-shadow: 0 8px 24px #0007;
}

.artist-block .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding-top: 8px;
}

.artist-block .image,
.artist-row .image {
    aspect-ratio: 1;
    background-color: #444;
    /* border-radius: 50%; */
    box-shadow: 0 8px 24px #0007;
    /* box-shadow: 0 8px 24px #62b6f6; */
    overflow: hidden;
    /* border: 2px solid #62b6f6; */
    border-radius: 4px;
}

.artist-block .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.artist-block .image img.placeholder {
    content: url(../img/artist_icon.svg);
    width: 80%;
    height: 80%;
    padding: 10%;
    opacity: 0.4;
}

.artist-row {
    width: 100%;
    padding: 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    box-sizing: border-box;
}

.artist-row .image {
    height: 80px;
    aspect-ratio: 1;
    flex: 0 0 auto;
}

.artist-row .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* .artist-block .image.medium {
    width: 136px;
    height: 136px;
}

.artist-block .image.small {
    width: 100px;
    height: 100px;
}


/* @media only screen and (max-width: 460px) {
    .artist-block .image.medium {
        width: 100px;
        height: 100px;
    }
    
    .artist-block .image.small {
        width: 64px;
        height: 64px;
    }
    
    .artist-block .image.tiny {
        width: 40px;
        height: 40px;
        box-shadow: 0 2px 2px #0004;
    }
} */

.grow {
    flex: 1;
}
