div#cards-panel {
  width: 460px;
  height: 100%;
  min-height: 0;
  box-sizing: border-box;
  flex: 1;
  padding: 0 20px 20px;
}

@media only screen and (max-width: 460px) {
  div#cards-panel {
    width: 100%;
  }
}

div#cards-panel a {
  color: inherit;
  text-decoration: none;
}

.card {
  width: 100%;
  height: auto;
  max-height: min(100%, 600px);
  box-sizing: border-box;
  pointer-events: all;
  background-color: #242424;
  border-radius: 4px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 6px 8px #0004;
}

.card.welcome {
  padding: 8px 16px;
}

.card.loading {
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.error {
  padding: 8px 16px;
}

.card.current-connection {
  max-width: 400px;
  flex-direction: column;
  display: flex;
}

.card.current-connection .header {
  border-bottom: 1px solid #444;
  flex-direction: row;
  justify-content: space-around;
  padding: 8px;
  display: flex;
}

.tracks-list {
  overflow-y: auto;
}

.tracks-list div.track {
  box-sizing: border-box;
  background-color: #1c1c1c;
  flex-direction: row;
  display: flex;
}

.tracks-list div.track a {
  box-sizing: border-box;
  min-width: 0;
  flex-direction: row;
  gap: 8px;
  padding: 14px;
  font-size: 12px;
  display: flex;
}

.tracks-list div.track a.main {
  flex-grow: 1;
  align-items: flex-start;
}

.tracks-list div.track a.play {
  flex-shrink: 0;
  align-items: center;
}

.tracks-list div.track a div {
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tracks-list div.track a div.song {
  height: 18px;
  width: 20px;
  opacity: .8;
  background-image: url("music_note_white_24dp.63fc29f5.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  flex: none;
}

.tracks-list div.track a div.play {
  height: 18px;
  width: 24px;
  opacity: .8;
  background-image: url("play_arrow_white_24dp.0d1dc673.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  flex: none;
}

.tracks-list div.track a div.pause {
  height: 18px;
  width: 24px;
  opacity: .8;
  background-image: url("pause_white_24dp.da61014a.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  flex: none;
}

.tracks-list div.track a.disabled {
  opacity: .2;
}

.tracks-list div.track a div.name {
  flex-grow: 1;
}

.tracks-list div.track a div.year {
  flex-shrink: 0;
}

.tracks-list div.track.more div {
  box-sizing: border-box;
  color: #aaa;
  padding: 14px;
  font-size: 12px;
  font-style: italic;
}

.tracks-list div.track a:not(.disabled):hover {
  cursor: pointer;
  background-color: #2c2c2c;
}

.tracks-list .track:not(:first-child) {
  border-top: 1px solid #ffffff0f;
}

.tracks-list .track.more:hover {
  background-color: #1c1c1c;
}

.card.current-artist {
  max-width: 400px;
  gap: 12px;
}

.card.current-artist .header-image-wrapper {
  height: 240px;
  position: relative;
}

@media only screen and (max-width: 460px) {
  .card.current-artist {
    max-width: unset;
  }

  .card.current-artist .header-image-wrapper {
    height: 210px;
  }
}

.card.current-artist .header-image {
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  margin: auto;
  position: absolute;
  inset: 0;
  box-shadow: 0 8px 24px #0007;
}

.artist-basic-info {
  cursor: pointer;
  background-color: #242424;
  flex-direction: column;
  gap: 8px;
  padding: 6px 12px 12px;
  display: flex;
}

.artist-basic-info:hover {
  background-color: #2c2c2c;
}

.artist-basic-info .block {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.artist-basic-info .name {
  color: #fff;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-size: 24px;
  font-weight: 700;
  display: -webkit-box;
  overflow: hidden;
}

.artist-basic-info .followers {
  color: #aaa;
  padding-left: 2px;
  font-size: 12px;
  font-weight: 400;
}

.artist-basic-info .logo {
  height: 28px;
  aspect-ratio: 10 / 3;
  background-image: url("spotify_logo.5980814e.svg");
  background-repeat: no-repeat;
  background-size: cover;
  flex: none;
  margin: 8px;
}

.artist-connections-list {
  width: 100%;
  scrollbar-gutter: stable;
  box-sizing: border-box;
  flex-direction: row;
  gap: 8px;
  padding: 8px 8px 12px;
  display: flex;
  overflow-x: auto;
}

.artist-connections-list > div {
  min-width: 0;
  flex: none;
}

.artist-image-placeholder {
  width: 150px;
  height: 150px;
  object-fit: cover;
  opacity: .4;
  margin: auto;
  position: absolute;
  inset: 0;
}

.artist-block {
  width: 116px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #2c2c2c;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
}

.artist-block.big {
  width: 152px;
  padding: 8px;
}

@media only screen and (max-width: 460px) {
  .artist-block {
    width: 100px;
    padding: 6px;
  }

  .artist-block.big {
    width: 128px;
    padding: 6px;
  }
}

.artist-block:hover {
  background-color: #3c3c3c;
  box-shadow: 0 8px 24px #0007;
}

.artist-block .name {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  padding-top: 8px;
  overflow: hidden;
}

.artist-block .image, .artist-row .image {
  aspect-ratio: 1;
  background-color: #444;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 8px 24px #0007;
}

.artist-block .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.artist-block .image img.placeholder {
  content: url("artist_icon.b8b2f971.svg");
  width: 80%;
  height: 80%;
  opacity: .4;
  padding: 10%;
}

.artist-row {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
}

.artist-row .image {
  height: 80px;
  aspect-ratio: 1;
  flex: none;
}

.artist-row .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grow {
  flex: 1;
}

:root {
  --brand-color: #63b7f7;
  --brand-color2: #ffbee4;
}

* {
  -webkit-user-select: none;
  user-select: none;
  transition: background-color .3s, opacity .3s, border-color .3s;
}

body, html {
  height: 100%;
  color: #ddd;
  margin: 0;
  padding: 0;
  font-family: Noto Sans, sans-serif;
}

html {
  background-color: #242424;
}

body {
  flex-direction: column;
  display: flex;
}

header {
  width: 100%;
  background-color: #303030;
  margin: 0;
}

main {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  font-size: 15px;
  line-height: 1.5rem;
}

h1 {
  font-size: 1.8em;
}

h2 {
  margin-block: 1.33em .1em;
}

h4 {
  margin-block: 1.33em .33em;
}

p, li {
  font-size: .9em;
}

@media only screen and (max-width: 560px) {
  .only-desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 560px) {
  .only-mobile {
    display: none !important;
  }
}

#graph-area, #overlays-area {
  height: 100%;
  width: 100%;
  flex-direction: column;
  flex: 1 0;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#overlays-area {
  pointer-events: none;
}

#overlays-area > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.top-bar {
  height: 40px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin: 20px;
  display: flex;
}

.top-bar > * {
  pointer-events: all;
}

.top-bar .app-logo {
  width: 150px;
}

.top-bar .app-logo img {
  width: 100%;
}

.top-bar .menu-section {
  height: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  margin-left: auto;
  display: flex;
}

.icon {
  width: 24px;
  height: 24px;
  opacity: .4;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  display: inline-block;
  position: relative;
}

.icon.info {
  background-image: url("info_white_24dp.d1532dbf.svg");
}

.icon.help {
  background-image: url("help_outline_white_24dp.835f1afa.svg");
}

.icon.config {
  background-image: url("settings_white_24dp.643c5801.svg");
}

.icon.search {
  background-image: url("search_white_24dp.d84a8855.svg");
}

.icon.close {
  background-image: url("close_white_24dp.0544334a.svg");
}

.top-bar .menu-icon {
  font-size: 0;
  position: relative;
}

.top-bar .menu-icon:hover .icon, .icon:hover {
  opacity: .8;
  cursor: pointer;
}

.top-bar .menu-icon .popover-menu {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.top-bar .menu-icon:hover .popover-menu {
  opacity: 1;
  pointer-events: all;
}

.popover-menu#config {
  width: 120px;
  background-color: #333;
  border-radius: 4px;
  flex-direction: column;
  gap: 8px;
  padding: 16px 8px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  top: 100%;
  right: 0;
}

input[type="checkbox"], input[type="checkbox"] + label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
}

.app-container {
  height: 100%;
  width: 100%;
  background: radial-gradient(closest-side, #2f2f2f, #141414);
  flex-direction: row;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

img {
  -webkit-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

canvas {
  width: 100%;
  height: 100%;
}

button.action {
  color: #fff;
  letter-spacing: .1em;
  text-align: center;
  text-transform: uppercase;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #fff5;
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
}

button.action:hover {
  background-color: #fff1;
  border-color: #fffa;
}

button.action:disabled {
  color: #888;
  pointer-events: none;
  border-color: #333;
}

.search-wrapper {
  width: 360px;
  height: 100%;
  z-index: 2;
  pointer-events: all;
  position: relative;
}

.search-wrapper.mobile {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin: -20px 0 20px;
  padding: 0 20px;
}

.search-wrapper.mobile .search-box-wrapper:after {
  right: 20px;
}

.search-box-wrapper {
  width: 100%;
  height: 100%;
  background-color: #4448;
  border-radius: 4px;
  overflow: hidden;
}

.search-box-wrapper:after {
  content: "";
  width: 40px;
  height: 100%;
  opacity: .4;
  background-image: url("search_white_24dp.d84a8855.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.search-wrapper input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #ccc;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.search-wrapper input:focus {
  background-color: #444a;
}

.search-wrapper input::placeholder {
  color: #fff;
  opacity: .4;
}

.search-wrapper input:focus::placeholder {
  opacity: .6;
}

.search-wrapper .search-results {
  width: 100%;
  box-sizing: border-box;
  height: 0;
  max-height: min(100vh - 80px, 500px);
  background-color: #333e;
  border-radius: 4px;
  flex-direction: column;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  transition: height .1s ease-out;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.search-wrapper .search-results ul {
  padding: unset;
  margin: 0;
}

.search-wrapper .search-results.show {
  min-height: 200px;
}

.search-wrapper .search-results li {
  cursor: pointer;
  border-top: 1px solid #0004;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 6px;
  list-style: none;
  display: flex;
}

.search-wrapper .search-results .no-results {
  box-sizing: border-box;
  color: #aaa;
  padding: 18px 24px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

.search-wrapper .search-results li.focus {
  background-color: #444;
}

.info-box {
  background-color: #0004;
  border-radius: 4px;
  margin: 16px;
  padding: 8px;
  font-size: 12px;
}

#project-box {
  pointer-events: all;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

#config-box, #debug-box {
  min-width: 150px;
  pointer-events: all;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

#debug-box .row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

#debug-box .row .value {
  font-weight: 700;
}

div.label {
  color: #444;
  font-size: 18px;
  font-weight: 700;
}

.collapsible-content {
  max-height: 0;
  transition: max-height .2s ease-out;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #fff3;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff4;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #fff1;
}

/*# sourceMappingURL=index.f7909007.css.map */
